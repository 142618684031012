import {
  ref,
  onMounted,
  getCurrentInstance,
  reactive
} from 'vue'
import {
  customerCompanyAddressUseCase,
  customerRetailUseCase,
  getAllKota
} from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers,
  maxLength,
  minLength
} from '@vuelidate/validators'

export default {
  name: 'AlamatPerusahaanForm',
  props: {
    data: Object,
    idCompany: null,
    username: null,
  },
  emits: ['closeSidebarForm', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const propertiesProps = reactive(props)
    const dataForm = reactive({
      CompanyId: null,
      Alias: null,
      Alamat: null,
      Telepon: null,
      Penerima: null,
      IsActive: true,
      Kota: null,
      KodePos: null,
      Latitude: null,
      Longitude: null,
      Note: null
    })
    const openMap = ref(false)
    const mapData = ref({
      selectedLocation: '',
      lat: '',
      lng: ''
    }) as any
    const companyUsername = propertiesProps.username
    const kotaOption = ref([])
    const Id = ref(null)
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const title = ref('Tambah Alamat Pengiriman')
    const submitted = ref(false)
    const addressRef = ref()
    const validTelp = (val: any) => {
      let valid = true
      // console.log(val.length)
      if (val.length > 1) {
        // console.log('1', val.slice(0, 2))
        if (val.charAt(0) !== '0') {
          if (val.slice(0, 2) !== '62') {
            valid = false
          }
        }
      } else if (val.length === 1) {
        if (val.charAt(0) !== '0' || val.charAt(0) !== '6') {
          // console.log('2', val.charAt(1))
          valid = false
        }
      }
      // console.log(valid)
      return valid
    }
    const rules = {
      Alias: {
        required: helpers.withMessage('Nama Alamat harus diisi', required),
        maxLength: helpers.withMessage('Nama Alamat maksimal 30 karakter', maxLength(30))
      },
      Alamat: {
        required: helpers.withMessage('Alamat Lengkap harus diisi', required),
      },
      Telepon: {
        required: helpers.withMessage('Telepon harus diisi', required),
        maxLength: helpers.withMessage('Telepon penerima maksimal 14 digit angka', maxLength(14)),
        minLength: helpers.withMessage('Telepon penerima minimal 10 digit angka', minLength(10)),
        validTelp: helpers.withMessage('Telepon harus diawali dengan 0 atau 62', validTelp),
      },
      Penerima: {
        required: helpers.withMessage('Penerima harus diisi', required),
        maxLength: helpers.withMessage('Nama Penerima maksimal 30 karakter', maxLength(30))
      },
      Kota: {
        required: helpers.withMessage('Kota harus diisi', required)
      },
      KodePos: {
        required: helpers.withMessage('Kode Pos harus diisi', required),
        maxLength: helpers.withMessage('Kode Pos harus 5 digit', minLength(5)),
        minLength: helpers.withMessage('Kode Pos harus 5 digit', maxLength(5)),
      },
      Latitude: {
        // required: helpers.withMessage('Latitude harus diisi', required)
      },
      Longitude: {
        // required: helpers.withMessage('Longitude harus diisi', required)
      },
      Note: {
        required: helpers.withMessage('Detail Alamat Lainnya harus diisi', required),
        maxLength: helpers.withMessage('Detail Alamat Lainnya maksimal 50 karakter', maxLength(50))
      }
    }
    const v$ = useVuelidate(rules, dataForm)
    if (propertiesProps.data) {
      title.value = 'Edit Alamat Pengiriman'
      Id.value = propertiesProps.data.Id
      dataForm.Alias = propertiesProps.data.Alias
      dataForm.Penerima = propertiesProps.data.Penerima
      dataForm.Telepon = propertiesProps.data.Telepon
      dataForm.IsActive = propertiesProps.data.IsActive
      dataForm.Alamat = propertiesProps.data.Alamat
      dataForm.Kota = propertiesProps.data.KotaId
      dataForm.KodePos = propertiesProps.data.KodePos
      dataForm.Latitude = propertiesProps.data.Latitude
      dataForm.Longitude = propertiesProps.data.Longitude
    }
    const closeSidebar = () => {
      emit('closeSidebarForm')
    }
    const checkResponse = async (response: any) => {
      // console.log('response after', response)
      if (response.error) {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      } else {
        if (propertiesProps.data) {
          $toast.add({
            severity: 'success',
            // summary: response.result.title,
            detail: response.result.detail ?? response.result.Detail,
            group: 'bc',
            life: 1500
          })
        } else {
          $toast.add({
            severity: 'success',
            detail: $strInd.toast.successSubmit,
            group: 'bc',
            life: 1500
          })
        }
        console.log('aaaa')
        closeSidebar()
        emit('reloadData')
      }
    }
    const deleteData = () => {
      $confirm.require({
        header: 'Alamat Pengiriman',
        message: $strInd.confirm.msgDelete,
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          const response = await customerCompanyAddressUseCase.deleteData(Number(Id.value))
          checkResponse(response)
        }
      })
    }
    const submitProccess = async () => {
      const data = {
        // CompanyId: Number(propertiesProps.idCompany),
        // KotaId: dataForm.Kota,
        Alias: dataForm.Alias,
        NamaPenerima: dataForm.Penerima,
        HpPenerima: dataForm.Telepon,
        Alamat1: dataForm.Alamat,
        Alamat2: dataForm.Kota,
        // KodePos: 0,
        Note: dataForm.Note,
        KodePos: dataForm.KodePos,
        Latitude: dataForm.Latitude,
        Longitude: dataForm.Longitude,
        IsPrimary: false
      }
      const response = await customerCompanyAddressUseCase.submitData(companyUsername, data)
      checkResponse(response)
    }
    const submitData = (isFormValid: boolean) => {
      // console.log(isFormValid)
      submitted.value = true
      if (!isFormValid) {
        return
      }
      submitProccess()
    }
    const getKota = async () => {
      const filters = {
        pageNumber: 1,
        pageSize: 100,
        search: '',
        filterField: ['name'],
        custom: []
      }
      // const response = await customerRetailUseCase.getSelectKota()
      const response = await getAllKota(filters)
      kotaOption.value = response.result.Data.map((x: any) => ({
        value: x.Code,
        label: x.Name
      }))
    }
    const setAddressResult = (result: any) => {
      dataForm.Alamat = result
    }
    onMounted(() => {
      getKota()
      console.log('username', companyUsername)
    })

    const receiveMapData = (result: any) => {
      openMap.value = false
      mapData.value = JSON.parse(result)
      console.log(mapData.value)
      dataForm.Alamat = mapData.value?.selectedLocation
      dataForm.Latitude = mapData.value?.lat
      dataForm.Longitude = mapData.value?.lng
    }

    return {
      dataForm,
      title,
      propertiesProps,
      closeSidebar,
      deleteData,
      submitProccess,
      checkResponse,
      kotaOption,
      getKota,
      rules,
      v$,
      submitData,
      setAddressResult,
      submitted,
      Id,
      addressRef,
      openMap,
      receiveMapData
    }
  }
}
