import {
  toRefs,
  reactive,
  getCurrentInstance,
  ref,
  onMounted
} from 'vue'
import CardPengiriman from '@/views/master/customers/company/detail/CardPengiriman.vue'
import moment from 'moment'
import {
  deliveryPendingUseCase
} from '@/domain/usecase'

export default {
  name: 'DeliveryHistory',
  components: {
    CardPengiriman
  },
  props: {
    filter: {
      default: () => null
    }
  },
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast,
      $numbers
    } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const dataSource = ref([])
    const isLoading = ref(false)
    const filters = {
      skip: 0,
      // top: $numbers.totalItemPerPage,
      top: 5,
      totalRecords: 0,
      filter: '',
      select: '&$select=Id,Guid,Resi,NamaPengirim,NamaPenerima,AlamatPengirim,AlamatPenerima,HpPengirim,HpPenerima,TotalBerat,TotalColly,KategoriBarang,KeteranganPenerima,CreatedDate,GrandTotal',
      expand: '&$expand=AgenAsal($select=Id,Nama;$expand=Kota($select=Id,Nama)), AgenTujuan($select=Id,Nama;$expand=Kota($select=Id,Nama)), StatusPengiriman($select=Id,Status,Kode,Attribute1,Attribute2)',
      sorting: 'CreatedDate desc',
      search: ''
    }

    const getDataDelivery = () => {
      isLoading.value = true
      // console.log('prororo', propertiesProps.filter)
      filters.filter = `CreatedDate gt ${moment(propertiesProps.filter.date).format('YYYY-MM-DD')} and CreatedDate lt ${moment(propertiesProps.filter.date).add(1, 'M').format('YYYY-MM-DD')} and Username eq '${propertiesProps.filter.username}'`
      deliveryPendingUseCase.getAll(filters).then((response: any) => {
        if (!response.error) {
          filters.totalRecords = response.count
          dataSource.value = response.result
          // eslint-disable-next-line no-use-before-define
          dataSource.value = dataSource.value.filter(removeIfResiIsNull)
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 1500
          })
        }
        isLoading.value = false
      })
    }

    // eslint-disable-next-line consistent-return
    const removeIfResiIsNull = (el: any) => {
      if (el.Resi !== null) {
        return el
      }
    }

    const onPage = (skip: any) => {
      filters.skip = skip
      getDataDelivery()
      const top = document.getElementById('section-trans-top') as any
      top.scrollIntoView({
        behavior: 'smooth',
      })
    }

    onMounted(() => {
      getDataDelivery()
    })

    const searchDelivery = (val: any) => {
      filters.search = val
      getDataDelivery()
    }

    return {
      filters,
      dataSource,
      onPage,
      isLoading,
      getDataDelivery,
      searchDelivery
    }
  }
}
