
/* eslint-disable prefer-destructuring,vue/no-dupe-keys */
import {
  toRefs
} from 'vue'
import moment from 'moment'

export default {
  props: {
    data: {
      default: () => Object
    }
  },
  setup(props: any, {
    emit
  }: any) {
    const data = toRefs(props).data
    return {
      data,
      moment
    }
  }
}
