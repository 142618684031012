import {
  computed,
  getCurrentInstance,
  onMounted,
  reactive,
  ref
} from 'vue'
import {
  companyUseCase,
  discountCompanyUseCase
} from '@/domain/usecase'
import useVuelidate from '@vuelidate/core'
import {
  required,
  helpers,
  minValue
} from '@vuelidate/validators'
import store from '@/store'

export default {
  name: 'ModalCompany',
  props: {
    isShow: {
      default: () => false
    },
    data: {
      default: () => null
    },
    from: {
      default: () => null
    }
  },
  emits: ['hideDialog', 'reloadData'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast
    } = app!.appContext.config.globalProperties
    const hideDialog = () => {
      emit('hideDialog')
    }
    const propertiesProps = reactive(props)
    const typeForm = propertiesProps.from
    const dataSource = propertiesProps.data
    const nominal = ref(0)
    const titleModal: any = ref(null)
    const descriptionModal: any = ref(null)
    const labelInput: any = ref(null)
    const textBtn: any = ref('Simpan')
    const level = ref(0)
    const showForm = ref(false)
    const levelDiskon = ref([])
    const submitted = ref(false)
    const tglCutoff = ref(1)
    const dataForm = reactive({
      level: null,
      nominal: 0,
      tglCutoff: 1,
    })
    let rules
    if (typeForm === 'ChangeDiscountLevel') {
      rules = {
        level: {
          required: helpers.withMessage('Level Diskon harus diisi', required)
        }
      }
    } else if (typeForm === 'ChangeTransactionLimits') {
      rules = {
        nominal: {
          required: helpers.withMessage('Limit Transaksi harus diisi', required),
          minValue: helpers.withMessage('Limit Transaksi harus melebihi 1 ', minValue(1))
        }
      }
    } else if (typeForm === 'ActivateTop') {
      rules = {
        nominal: {
          required: helpers.withMessage('Nominal Deposit harus diisi', required),
          minValue: helpers.withMessage('Nominal Deposit harus melebihi 1 ', minValue(1))
        },
      }
    } else if (typeForm === 'ChangeCutoffDate') {
      rules = {
        tglCutoff: {
          required: helpers.withMessage('Tanggal Jatuh Tempo harus diisi', required),
          minValue: helpers.withMessage('Tanggal Jatuh Tempo harus melebihi 0 ', minValue(1))
        },
      }
    }
    const v$ = useVuelidate(rules, dataForm)

    const isShowModal = computed({
      get: () => propertiesProps.isShow,
      set: (val) => {
        if (!val) {
          hideDialog()
        }
      }
    })

    const getLevelDisCount = () => {
      discountCompanyUseCase.getAll({
        custom: 'IsActive eq true',
        select: '&$select=Id, Kelas, Keterangan, MinTransaksi'
      }).then((response: any) => {
        if (!response.error) {
          levelDiskon.value = response.result.map((x: any) => {
            x.label = `Level ${x.Kelas}`
            x.value = x.Id
            return x
          })
        }
      })
    }

    const setTemplate = () => {
      showForm.value = true
      if (typeForm === 'ActivateTop') {
        titleModal.value = 'Deposit Top'
        descriptionModal.value = 'Masukkan Nominal deposit yang harus dibayarkan oleh company'
        labelInput.value = 'Nominal Deposit'
        textBtn.value = 'Deposit'
      } else if (typeForm === 'ChangeTransactionLimits') {
        titleModal.value = 'Limit Transaksi'
        descriptionModal.value = 'Masukkan Nominal Limit Transaksi dalam satu bulan tiap company'
        labelInput.value = 'Limit Transaksi'
      } else if (typeForm === 'ChangeDiscountLevel') {
        titleModal.value = 'Level Diskon'
        descriptionModal.value = 'Pilih level diskon untuk diterapkan pada company tersebut'
        labelInput.value = 'Level Diskon'
        getLevelDisCount()
      } else if (typeForm === 'ChangeCutoffDate') {
        titleModal.value = 'Jatuh tempo/bulan'
        descriptionModal.value = 'Pilih tanggal untuk ditetapkan sebagai tanggal jatuh tempo tiap bulan pada company tersebut'
        labelInput.value = 'Tanggal Jatuh Tempo/Bulan'
      }
    }

    const showToast = (response: any) => {
      if (!response.error) {
        $toast.add({
          severity: 'success',
          detail: response.result.detail ?? response.result.Detail,
          group: 'bc',
          life: 3000
        })
        hideDialog()
        emit('reloadData')
      } else {
        $toast.add({
          severity: 'error',
          detail: response.message,
          group: 'bc',
          life: 3000
        })
      }
      store.dispatch('hideLoading')
    }

    const processChangeLevelDiscount = () => {
      companyUseCase.submitLevelDiscount(dataSource.Id, {
        LevelDiskonId: dataForm.level
      }).then((response: any) => {
        showToast(response)
      })
    }

    const processActivateTop = () => {
      companyUseCase.submitPayTop({
        MsUserId: dataSource.Id,
        Nominal: dataForm.nominal
      }).then((response: any) => {
        showToast(response)
      })
    }

    const processChangeTransLimits = () => {
      companyUseCase.submitPaylaterLimit(dataSource.Id, {
        Limit: dataForm.nominal
      }).then((response: any) => {
        showToast(response)
      })
    }

    const processChangeCutoffDate = () => {
      companyUseCase.submitCutoffDate(dataSource.Id, {
        PaylaterCutoffDate: dataForm.tglCutoff
      }).then((response: any) => {
        showToast(response)
      })
    }

    const proccessSubmit = () => {
      store.dispatch('showLoading')
      if (typeForm === 'ActivateTop') {
        processActivateTop()
      } else if (typeForm === 'ChangeTransactionLimits') {
        processChangeTransLimits()
      } else if (typeForm === 'ChangeDiscountLevel') {
        processChangeLevelDiscount()
      } else if (typeForm === 'ChangeCutoffDate') {
        processChangeCutoffDate()
      }
    }

    const submitData = (isFormValid: boolean) => {
      submitted.value = true
      if (!isFormValid) {
        return
      }
      proccessSubmit()
    }

    onMounted(() => {
      setTemplate()
    })

    return {
      isShowModal,
      hideDialog,
      nominal,
      titleModal,
      descriptionModal,
      labelInput,
      textBtn,
      level,
      levelDiskon,
      typeForm,
      submitData,
      v$,
      submitted,
      rules,
      dataForm,
      showForm,
      tglCutoff
    }
  }
}
