import {
  onMounted,
  ref,
  toRefs,
  reactive,
  getCurrentInstance
} from 'vue'
import {
  companyUseCase
} from '@/domain/usecase'
import moment from 'moment'
import {
  useStore
} from 'vuex'
import {
  encryptDecriptMethods
} from '@/plugins/encryptDecriptMethods'
import {
  useRoute,
  useRouter
} from 'vue-router'

export default {
  props: {
    filterDate: {
      default: () => null
    },
  },
  emits: ['toHistory'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const {
      $toast,
      $numbers
    } = app!.appContext.config.globalProperties
    const propertiesProps = reactive(props)
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const dataSource = ref([]) as any
    const isInitial = ref(false) /** variable yang digunakan untuk mereset state menggunakan plugin vue-eternal-loading */
    const isLoading = ref(false)
    const Id = () => encryptDecriptMethods.decrypt(route.params.id)
    const filters = {
      skip: 0,
      // top: $numbers.totalItemPerPage,
      top: 10,
      totalRecords: 0,
      page: 1,
      custom: `CreatedAt gt ${moment(propertiesProps.filterDate).format('YYYY-MM-DD')} and CreatedAt lt ${moment(propertiesProps.filterDate).add(1, 'M').format('YYYY-MM-DD')} and MsUserId eq ${Id()}`,
      // select: '&$select=Id,TransPengirimanHdId,KurirTipePengirimanId,KurirMsUserId,IsActive',
      // expand: '&$expand=KurirMsUser($select=Username), TransPengirimanHd($expand=StatusPengiriman($select=Id,Status,Kode);$select=Id,AgenAsalId,AgenTujuanId,AlamatPenerima,AlamatPengirim,HpPenerima,HpPengirim,JenisPembayaran,Resi,TipePengiriman,TotalBerat,TotalColly,LastUpdate,KategoriBarang,NamaPengirim,StatusPengirimanId)',
      sorting: '',
      search: ''
    }

    const page = ref(1)

    const getDataCutoff = async () => {
      isLoading.value = true
      filters.custom = `period=${moment(propertiesProps.filterDate).format('YYYY-MM-DD')}&userId=${Id()}&PageNumber=${page.value}`
      companyUseCase.getCutOff(filters).then((response: any) => {
        if (!response.error) {
          // console.log('res', response.result)
          filters.totalRecords = response.result.Count
          dataSource.value = response.result
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 1500
          })
        }
        isLoading.value = false
      })
    }

    const onPage = (event: any) => {
      // console.log('aaa', event)
      page.value = event.page + 1
      // filters.skip = skip
      getDataCutoff()
      // const top = document.getElementById('section-trans-top') as any
      // top.scrollIntoView({
      //   behavior: 'smooth',
      // })
    }

    const toHistory = (val: any) => {
      // router.push({
      //   name: 'master-company-detail-history',
      //   params: {
      //     idCut: val.Id
      //   }
      // })
      emit('toHistory', val)
    }

    onMounted(() => {
      getDataCutoff()
    })

    const searchTOP = (val: any) => {
      filters.search = val
      getDataCutoff()
    }

    return {
      dataSource,
      isLoading,
      moment,
      isInitial,
      filters,
      onPage,
      getDataCutoff,
      toHistory,
      store,
      searchTOP
    }
  }
}
