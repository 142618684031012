
import {
  onMounted,
  toRefs,
  ref,
  getCurrentInstance
} from 'vue'
import {
  useRouter
} from 'vue-router'
import {
  companyUseCase
} from '@/domain/usecase'
import {
  useStore
} from 'vuex'
import moment from 'moment'
import CardPengiriman from '@/views/master/customers/company/detail/CardPengiriman.vue'

export default {
  components: {
    CardPengiriman
  },
  props: {
    dataFilter: {
      default: () => null
    }
  },
  emits: ['goBack'],
  setup(props: any, {
    emit
  }: any) {
    const app = getCurrentInstance()
    const router = useRouter()
    const {
      dataFilter
    } = toRefs(props)
    const {
      $toast,
      $socketHub,
      $swal,
      $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const dataSource = ref(null) as any
    const dataSourceDetail = ref([]) as any
    const dataSourceCutOffDeliveryDetail = ref([]) as any
    const isLoading = ref(false)
    const closeSidebar = () => {
      router.back()
      // router.push({
      //   name: 'master-company'
      // })
    }

    const filter = {
      id: '',
      search: ''
    }

    const getAllDetail = () => {
      store.dispatch('showLoading')
      isLoading.value = true
      companyUseCase.getDetailCutoff(dataFilter.value.Id).then((response: any) => {
        // console.log('response', response)
        if (!response.error) {
          dataSource.value = response.result
          // dataSource.value.TransPengirimanHds = response.result.TransPengirimanHds.map((x: any) => {
          //   x.StatusPengiriman
          //   return x
          // })
        }
        isLoading.value = false
        store.dispatch('hideLoading')
      })
    }

    const getCutOffDelivery = () => {
      store.dispatch('showLoading')
      isLoading.value = true
      companyUseCase.getTransPaylater(dataFilter.value.Id).then((response: any) => {
        if (!response.error) {
          dataSourceDetail.value = response.result
          // console.log('datasourcedetailX', dataSourceDetail.value)
          // eslint-disable-next-line no-use-before-define
          getCutOffDeliveryIds(dataSourceDetail.value)
          // eslint-disable-next-line no-use-before-define
          getCutOffDeliveryDetail()
          // console.log('datasourcedetailY', dataSourceDetail.value)
          // console.log('find', dataSourceDetail.value.filter((x: any) => x.PengirimanHdId === 332134))
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 3000
          })
        }
        isLoading.value = false
        store.dispatch('hideLoading')
      })
    }

    const arrayTemp = ref([]) as any
    // const tempResult = ref() as any
    const getCutOffDeliveryDetail = () => {
      store.dispatch('showLoading')
      isLoading.value = true
      const ids = arrayTemp.value.join(',')
      // console.log('arrayTempX', arrayTemp.value.join(','))
      companyUseCase.getDetailCutOffDelivery(ids).then((response: any) => {
        // console.log('respres', response)
        if (!response.error) {
          dataSourceCutOffDeliveryDetail.value = response.result
          dataSourceCutOffDeliveryDetail.value.map((x: any) => {
            const getStatus = dataSourceDetail.value.filter((a: any) => a.PengirimanHdId === x.Id)
            x.Status = getStatus[0].Status
            return x
          })
          // console.log('dataSourceCutOffDeliveryDetail', dataSourceCutOffDeliveryDetail.value)
          // eslint-disable-next-line no-use-before-define
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 3000
          })
        }
        isLoading.value = false
        store.dispatch('hideLoading')
      })
    }

    const getCutOffDeliveryIds = (value: any) => {
      // eslint-disable-next-line array-callback-return
      value.map((x: any) => {
        arrayTemp.value.push(x.PengirimanHdId)
      })
      // getCutOffDeliveryDetail()
    }

    const goBack = () => {
      emit('goBack')
    }

    onMounted(() => {
      getAllDetail()
      getCutOffDelivery()
      console.log('datasourcedetail', dataSourceDetail)
    })
    return {
      closeSidebar,
      dataSource,
      moment,
      goBack,
      isLoading,
      store,
      dataSourceDetail,
      dataSourceCutOffDeliveryDetail
    }
  }
}
