import { companyUseCase } from '@/domain/usecase'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import { onMounted, ref, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { isEmpty, isNil, isNull } from 'lodash'
import moment from 'moment'
import ModalCompany from '@/views/master/customers/company/modal/ModalCompany.vue'
import TopHistory from '@/views/master/customers/company/detail/TopHistory.vue'
import DeliveryHistory from '@/views/master/customers/company/detail/DeliveyHistory.vue'
import { environment } from '@/utils'
import DetailHistoryTop from '@/views/master/customers/company/detail/DetailHistoryTop.vue'
import AlamatPerusahaanForm from '@/views/master/customers/customer/customer-company/detail/AlamatPerusahaanForm.vue'

export default {
  name: 'CompanyDetail',
  components: {
    ModalCompany,
    TopHistory,
    DeliveryHistory,
    DetailHistoryTop,
    AlamatPerusahaanForm
  },
  setup() {
    const app = getCurrentInstance()
    const {
      $toast,
      $confirm,
      $swal,
      $socketHub,
      $strInd
    } = app!.appContext.config.globalProperties
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const sliderActive = ref(true)
    const sliderActiveForm = ref(false)
    const Id = () => encryptDecriptMethods.decrypt(route.params.id)
    const dataSource = ref(null) as any
    const valBtn = ref(0)
    const valBtnTrans = ref(0)
    const op = ref()
    const dataForm = ref()
    const showGeneralInfo = ref(true)
    const showSavedAddress = ref(false)
    const showCompanyData = ref(true)
    const showTOPTransaction = ref(true)
    const isShowModalCompany = ref(false)
    const fromModal = ref(null)
    const isHistory = ref(false)
    const IsPrimary = ref(false)
    const companyTypeOption = [{
      Nama: 'BESAR',
      Keterangan: 'Perusahaan “Besar” hanya dapat mengirimkan barang yang besar dan berat',
      value: 'big'
    },
    {
      Nama: 'Kecil',
      Keterangan: 'Perusahaan “Kecil” hanya dapat mengirimkan barang yang kecil seperti dokumen dsb',
      value: 'small'
    }]
    const disableVerifyButton = ref(true)
    const selectedCompanyType = ref('') as any
    const listBtn = [{
      id: 0,
      title: 'Informasi Umum'
    },
    {
      id: 1,
      title: 'Alamat Tersimpan'
    }]
    const listBtnTrans = [{
      id: 0,
      title: 'Per Tagihan'
    },
    {
      id: 1,
      title: 'Per Resi'
    }]
    const closeSidebar = () => {
      router.back()
      // router.push({
      //   name: 'master-company'
      // })
    }

    const processSumDeposits = (list: any) => {
      let sumArr = 0
      if (list.length > 0) {
        const now = moment()
        const dataExist = list[list.length - 1]
        const tempArr = list.slice(0, list.length - 1)
        sumArr = dataExist.Nominal
        tempArr.forEach((element: any) => {
          if (element.IsPaid === true && element.ExpiredTime !== null) {
            if (now.diff(moment(element.ExpiredTime), 'days') >= 0) {
              sumArr += element.Nominal
            }
          }
        })
        console.log('arr', sumArr)
      } return sumArr
    }

    const getDetail = () => {
      store.dispatch('showLoading')
      companyUseCase.getProfile(Id()).then((response) => {
        if (!response.error) {
          dataSource.value = response.result
          console.log('dataSource', dataSource.value)
          dataSource.value.TotalDeposit = response.result.ActiveDeposit
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 3000
          })
          router.back()
        }
        store.dispatch('hideLoading')
      })
    }

    const choiceBtn = (val: any, fromBtn: any) => {
      if (fromBtn === 'companyData') {
        valBtn.value = val.id
      } else if (fromBtn === 'transaksi') {
        valBtnTrans.value = val.id
      }
      // if (val.id === 1) {
      //   showSavedAddress.value = true
      //   showGeneralInfo.value = false
      // } else {
      //   showSavedAddress.value = false
      //   showGeneralInfo.value = true
      // }
    }

    const classItemBtn = (val: any) => {
      if (val.id === valBtn.value) {
        return 'chip-orange'
      }
      return 'grey-bg-no-border'
    }

    const classItemBtnTrans = (val: any) => {
      if (val.id === valBtnTrans.value) {
        return 'chip-orange'
      }
      return 'grey-bg-no-border'
    }

    const nameEmpty = (val: any) => {
      if (isEmpty(val)) {
        return '-'
      } return val
    }

    const sumKoli = (val: any) => {
      let total = 0
      val.forEach((element: any) => {
        total += element.TotalColly
      })
      return total
    }

    const sumBerat = (val: any) => {
      let total = 0
      val.forEach((element: any) => {
        total += element.TotalBerat
      })
      return total
    }

    const processVerify = () => {
      companyUseCase.submitVerify(dataSource.value.Id, { type: selectedCompanyType.value }).then((response) => {
        if (!response.error) {
          $toast.add({
            severity: 'success', detail: response.result.detail ?? response.result.Detail, group: 'bc', life: 1500
          })
          closeSidebar()
          $socketHub.emit('reloadVerify')
        } else {
          $toast.add({
            severity: 'error',
            detail: response.message,
            group: 'bc',
            life: 3000
          })
        }
      })
    }

    const submitVeifyAccount = () => {
      $swal.fire({
        title: 'Verifikasi Akun',
        text: `Apakah anda yakin ingin memverifikasi ${dataSource.value.Fullname}`,
        icon: 'question',
        // imageUrl: require('@/assets/img/icon/printer.svg'),
        // imageWidth: 400,
        // imageHeight: 150,
        // imageAlt: 'Custom image',
        showCancelButton: true,
        confirmButtonColor: '#ff9800',
        cancelButtonColor: '#90A4AE',
        allowOutsideClick: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result: any) => {
        if (result.isConfirmed) {
          processVerify()
        }
      })
    }

    const checkDocument = (param: any, val: any) => {
      let x = false
      val.forEach((element: any) => {
        if (element.Kind === param) {
          x = true
        }
      })
      return x
    }

    const getThumbnail = (param: any, val: any) => {
      let y = null
      val.forEach((element: any) => {
        if (element.Kind === param) {
          y = element.Thumbnail
          y = y.split('/')
          y = y[y.length - 1]
        }
      })
      return y
    }

    const getFilename = (param: any, val: any) => {
      let y = null
      val.forEach((element: any) => {
        if (element.Kind === param) {
          y = element.Filename
          y = y.split('/')
          y = y[y.length - 1]
        }
      })
      return y
    }

    const showCompanyDataField = () => {
      showCompanyData.value = !showCompanyData.value
    }

    const showTOPTransactionField = () => {
      showTOPTransaction.value = !showTOPTransaction.value
    }

    const rotateArrowIcon = (val: any) => {
      if (val) {
        return 'pi pi-angle-down'
      }
      return 'pi pi-angle-right'
    }

    const isActived = async (val: any) => {
      store.dispatch('showLoading')
      const dataSend = {
        IsActive: val.IsActive
      }
      const response = await companyUseCase.changeIsActiveUser(val.LoginId, dataSend)
      if (response.error) {
        val.IsActive = !val.IsActive
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: `${response.result.title ?? response.result.Title} ${response.result.detail ?? response.result.Detail}`, group: 'bc', life: 1500
        })
        $socketHub.emit('reloadCompany')
      }
      store.dispatch('hideLoading')
    }

    const changeIsActive = (val: any) => {
      $swal.fire({
        title: 'Status Aktif',
        text: $strInd.confirm.msgChange,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F44336',
        cancelButtonColor: '#90A4AE',
        allowOutsideClick: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result: any) => {
        if (result.isConfirmed) {
          isActived(val)
        }
        if (result.isDismissed) {
          val.IsActive = !val.IsActive
        }
      })
    }

    const isActivateTop = (val: any, form: any) => {
      store.dispatch('showLoading')
      companyUseCase.changeIsPayLater(form.Id, {
        IsCanPaylater: val
      }).then((response: any) => {
        if (response.error) {
          $toast.add({
            severity: 'error', detail: response.message, group: 'bc', life: 1500
          })
        } else {
          $toast.add({
            severity: 'success', detail: `${response.result.title ?? response.result.Title} ${response.result.detail ?? response.result.Detail}`, group: 'bc', life: 1500
          })
          dataSource.value.IsCanPaylater = val
          $socketHub.emit('reloadCompany')
        }
        store.dispatch('hideLoading')
      })
    }

    const changeActivateTOP = (val: any, form: any) => {
      $swal.fire({
        title: 'Status TOP',
        text: $strInd.confirm.msgChange,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F44336',
        cancelButtonColor: '#90A4AE',
        allowOutsideClick: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result: any) => {
        if (result.isConfirmed) {
          isActivateTop(val, form)
        }
      })
    }

    const isPrimaryAddress = async (val: any) => {
      store.dispatch('showLoading')
      const response = await companyUseCase.changeIsPrimaryAddress(dataSource.value.LoginId, val.Id)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: `${response.result.title ?? response.result.Title} ${response.result.detail ?? response.result.Detail}`, group: 'bc', life: 1500
        })
        // $socketHub.emit('reloadCompany')
        getDetail()
      }
      store.dispatch('hideLoading')
    }

    const changeIsPrimaryAddress = (val: any, data: any) => {
      console.log('val', val)
      $swal.fire({
        title: 'Status Alamat Utama',
        text: $strInd.confirm.msgChange,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F44336',
        cancelButtonColor: '#90A4AE',
        allowOutsideClick: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result: any) => {
        if (result.isConfirmed) {
          isPrimaryAddress(val)
        }
        if (!result.isConfirmed) {
          val.IsPrimary = !val.IsPrimary
        }
      })
    }

    const withdrawDeposit = async (val: any) => {
      const isClean = !!await val.Paylaters.find((x: any) => x.Status !== 'settlement')
      console.log('aww', [isClean, val.Id])
      $swal.fire({
        title: 'Penarikan Deposit',
        text: 'Apakah anda yakin ingin melakukan penarikan deposit?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F44336',
        cancelButtonColor: '#90A4AE',
        allowOutsideClick: false,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }).then((result: any) => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-use-before-define
          withdrawDepositProcess(val.Id)
        }
      })
    }

    const withdrawDepositProcess = async (id: any) => {
      store.dispatch('showLoading')
      const response = await companyUseCase.submitWithdrawDeposit(id)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          severity: 'success', detail: `${response.result.title ?? response.result.Title} ${response.result.detail ?? response.result.Detail}`, group: 'bc', life: 1500
        })
        // $socketHub.emit('reloadCompany')
        getDetail()
      }
      store.dispatch('hideLoading')
    }

    const openModal = (from: any, val: any) => {
      fromModal.value = from
      isShowModalCompany.value = true
    }

    const showDropDown = (evt: any, val: any) => {
      op.value.toggle(evt)
      dataForm.value = val
    }

    const hideModalCompany = () => {
      isShowModalCompany.value = false
    }

    const dateOp = ref()
    const dateFilter = ref()
    const showListTransaksi = ref(false)

    const refTopHistory = ref()
    const refDeliveryHistory = ref()
    const saveFilterDate = async () => {
      showListTransaksi.value = true
      await dateOp.value.hide()
      if (!isNil(dateFilter.value) && showListTransaksi.value === true) {
        await refTopHistory.value.getDataCutoff()
        await refDeliveryHistory.value.getDataDelivery()
      }
    }

    const clearFilterDate = () => {
      console.log('clear data')
    }

    const showDropDownDate = (evt: any) => {
      dateOp.value.toggle(evt)
    }

    const reloadData = () => {
      getDetail()
      hideModalCompany()
    }

    const detailCutoff = ref(null)
    const toHistory = (val: any) => {
      detailCutoff.value = val
      isHistory.value = true
    }

    const generateCutOff = async (val: any) => {
      store.dispatch('showLoading')
      const response = await companyUseCase.generateCutOffTOP(val)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: response.message, group: 'bc', life: 1500
        })
      } else {
        $toast.add({
          // severity: 'success', detail: `${response.result.title} ${response.result.detail}`, group: 'bc', life: 1500
          severity: 'success', detail: 'Cut Off TOP berhasil', group: 'bc', life: 1500
        })
        // $socketHub.emit('reloadCompany')
        getDetail()
      }
      store.dispatch('hideLoading')
    }

    const pickedCompanyType = (val: any) => {
      // console.log('val', val)
      // console.log('val', val.value)
      selectedCompanyType.value = val.value
      if (isNull(selectedCompanyType.value)) {
        disableVerifyButton.value = true
      } else {
        disableVerifyButton.value = false
      }
      // console.log('tes', selectedCompanyType)
      // console.log('tes', selectedCompanyType.value)
    }

    onMounted(() => {
      getDetail()
    })

    const submitSearchTOP = async (val: any) => {
      if (!isNil(dateFilter.value) && showListTransaksi.value === true) {
        await refTopHistory.value.searchTOP(val)
      }
    }

    const submitSearchDelivery = async (val: any) => {
      if (!isNil(dateFilter.value) && showListTransaksi.value === true) {
        await refDeliveryHistory.value.searchDelivery(val)
      }
    }

    const redirectAdd = () => {
      const anu = document.getElementById('anu')
      if (anu !== null) {
        console.log('anu', anu)
        // anu.style.zIndex = '1001'
        anu.classList.replace('type-bus-sidebar', 'below-type-bus-sidebar')
      }
      dataForm.value = null
      sliderActiveForm.value = true
    }

    const closeSidebarForm = () => {
      const anu = document.getElementById('anu')
      console.log('aaa')
      sliderActiveForm.value = false
      if (anu !== null) {
        anu.classList.replace('below-type-bus-sidebar', 'type-bus-sidebar')
      }
    }

    return {
      sliderActive,
      route,
      closeSidebar,
      dataSource,
      isEmpty,
      classItemBtn,
      choiceBtn,
      listBtn,
      nameEmpty,
      moment,
      sumKoli,
      sumBerat,
      op,
      checkDocument,
      getThumbnail,
      getFilename,
      showGeneralInfo,
      showSavedAddress,
      showCompanyData,
      showTOPTransaction,
      showCompanyDataField,
      showTOPTransactionField,
      rotateArrowIcon,
      submitVeifyAccount,
      hideModalCompany,
      showDropDown,
      openModal,
      reloadData,
      isShowModalCompany,
      fromModal,
      dataForm,
      valBtn,
      changeIsActive,
      environment,
      changeActivateTOP,
      dateFilter,
      dateOp,
      saveFilterDate,
      clearFilterDate,
      listBtnTrans,
      valBtnTrans,
      classItemBtnTrans,
      showDropDownDate,
      showListTransaksi,
      refTopHistory,
      isHistory,
      toHistory,
      detailCutoff,
      refDeliveryHistory,
      IsPrimary,
      changeIsPrimaryAddress,
      generateCutOff,
      submitSearchTOP,
      submitSearchDelivery,
      redirectAdd,
      sliderActiveForm,
      closeSidebarForm,
      withdrawDeposit,
      companyTypeOption,
      selectedCompanyType,
      disableVerifyButton,
      pickedCompanyType
    }
  }
}
